<template>
<!-- logo墙 -->
<div class="cooperation" v-if="imgdata.length > 0"
 :style="logoBG[0]=='#'?'background:' + logoBG : 'backgroundImage:'+`url(${logoBG})`">
    <div class="coopBox" id="scroll"> 
        <div style="min-height: 95%;">
            <div class="coopCon">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <van-empty :image="require('@/assets/null.svg')" v-else-if="imgdata.length == 0"/>
                <div v-else class="content_item" v-for="(item,index) in imgdata" :key="index">
                    <div class="img-center" @click="linkClick(item)">
                    <img v-lazy="item.partner_image_url" alt="">
                    </div>
                </div>
                <div class="content_item" style="box-shadow:none;background:none;" v-if="this.imgdata.length%2 == 1"></div>
            </div>
        </div>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
<div class="cooperation" v-else>
    <div class="coopBox" id="scroll"> 
        <div style="min-height: 95%;">
            <div class="coopCon">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <van-empty :image="require('@/assets/null.svg')" v-else-if="imgdata.length == 0"/>
                <div v-else class="content_item" v-for="(item,index) in imgdata" :key="index">
                    <div class="img-center" @click="linkClick(item)">
                    <img v-lazy="item.partner_image_url" alt="">
                    </div>
                </div>
                <div class="content_item" style="box-shadow:none;background:none;" v-if="this.imgdata.length%2 == 1"></div>
            </div>
        </div>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
</template>
<script>
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
        Support
    },
    data(){
        return{
            Loading:false,
            imgdata:[],
            page_style:{},//背景颜色
            lightpage:1,//默认显示第一条数据
            islastpage:false,//判断是不是最后一页数据
            logoBG: '',
            flushbonading: true,
            site_id:'',
            show_grouping:'',
        }
    },
    computed: {
        logoColor(){
            if(this.logoBG[0]=='#'){
                return true
            }else{
                return false
            }
        }
    },
    created(){
        this.params = this.$route.query;
        this.page_style = window.sessionStorage.getItem('background_setting')
        this.logoBG = localStorage.getItem('logoBG')
    },
    mounted(){
        if(this.params.module_id){
            this.show_partner_info()
        }else{
            if(this.$route.params.site_id){
                sessionStorage.setItem('site_id', JSON.stringify(this.$route.params.site_id))
                sessionStorage.setItem('show_grouping', JSON.stringify(this.$route.params.show_grouping))
                this.site_id = this.$route.params.site_id
                this.show_grouping = this.$route.params.show_grouping
                this.showroom_logo_wall_list()
            }else{
                this.site_id = JSON.parse(sessionStorage.getItem('site_id'))
                this.show_grouping = JSON.parse(sessionStorage.getItem('show_grouping'))
                this.showroom_logo_wall_list()
            }
        }
    },
    computed: {
        ...mapState(['themecolors'])
    },
    methods:{
        linkClick(item){
            if(item.partner_link){
                window.location.href=item.partner_link
            }
        },
        // 展厅展示logo墙分类列表
        showroom_logo_wall_list(){
            this.$store.dispatch("showroom_logo_wall_list",{
                site_id:this.site_id,
                show_grouping:this.show_grouping
            }).then(res=>{
                if(res.data.code === 200){
                    document.title = res.data.data.module_title
                    this.imgdata = res.data.data.module_content
                }
            })
        },
        show_partner_info(){
            this.Loading = true;
            this.$store.dispatch("show_partner_info",{
                site_id:this.params.site_id,
                module_id:this.params.module_id,
            }).then(res=>{
                this.Loading = false;
                if(res.data.code == 200){
                    // if(res.data.data.last_page == 0){
                    //     this.islastpage = true;
                    //     return
                    // }
                    document.title = res.data.data.module_title ? res.data.data.module_title :  (sessionStorage.getItem('wei-title') || '轻微站');
                    this.imgdata = res.data.data.module_content;
                    // if(this.lightpage >= res.data.data.last_page){
                    //     this.islastpage = true;
                    // }
                }
            })
        },
        // lightScroll(e){
        //     if(this.islastpage){
        //         return
        //     }
        //     if(e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight - 40){ 
        //         this.lightpage += 1 ;
        //         this.show_partner_info();
        //     }
        // }
    },
}
</script>
<style scoped lang="less">
   /deep/.van-empty,/deep/.van-loading{
        position: absolute;
        top: 30%;
        left:50%;
        transform:translateX(-50%);
    }
    /deep/.van-empty{
        width: 100%;
        .van-empty__image{
            width: 300px;
            height: 300px;
        }
    }
    /deep/.van-loading {
        top: 50%;
    }
    .cooperation{
        background-size: 100% auto!important;
        background: no-repeat;
        box-sizing: border-box;
    }
    .coopBox{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        .coopCon{
            width: 88%;
            justify-content: space-around;
            // justify-content: center;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 15px 0;
            .content_item{
                width: 44%;
                height: 75px;
                background: rgba(255,255,255,0.9);
                // margin:0 0 8px 8px;
                margin-top: 12px;
                border-radius:10px;
                position: relative;
                box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.05),0px 0px 2px 1px rgba(0,0,0,0.05),2px 0px 2px 1px rgba(0,0,0,0.05),0px 2px 2px 1px rgba(0,0,0,0.05);
                .img-center{
                    position: absolute;
                    width: 100%;
                    height: 75px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    img{
                        height: 45px;
                        max-width: 90%;
                    }
                }
            }
        }
    }
</style>